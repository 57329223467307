<template>
    <div>
        <v-card id="details">
            <v-card-title>
                <v-btn small icon @click="close" color="primary" class="mr-2">
                    <v-icon>
                        mdi-close
                    </v-icon>
                </v-btn>
                <h3>Release</h3>
            </v-card-title>
            <v-divider class="ml-4 mr-5" />
            <v-card-subtitle>
                <div>
                    <p class="pa-0 mx-0 my-2">
                        <strong>ORDER: </strong
                        >{{ item.orderNumber || item.order }}
                    </p>
                </div>
                <div>
                    <p class="pa-0 mx-0 my-2">
                        <strong>Description: </strong>{{ item.description }}
                    </p>
                </div>
                <div>
                    <p class="pa-0 mx-0 my-2">
                        <strong>Quantity Reserved: </strong
                        >{{ item.quantity || item.free_use }}
                    </p>
                </div>
            </v-card-subtitle>
            <v-card-text class="mt-n5">
                <v-form v-model="valid">
                    <v-row no-gutters class="my-2">
                        <v-col cols="12">
                            <v-text-field
                                v-if="item.projectName"
                                v-model="item.projectName"
                                prepend-icon="mdi-account-hard-hat"
                                hide-details
                                :disabled="true"
                                label="Source Project"
                            >
                            </v-text-field>
                            <v-text-field
                                v-if="item.project_name"
                                v-model="item.project_name"
                                prepend-icon="mdi-account-hard-hat"
                                hide-details
                                :disabled="true"
                                label="Source Project"
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-2">
                        <v-col cols="12">
                            <v-autocomplete
                                :loading="loading"
                                v-model="selectedProject"
                                :items="projects"
                                item-text="name"
                                item-value="id"
                                prepend-icon="mdi-account-hard-hat"
                                hide-details
                                label="Target Project"
                            >
                            </v-autocomplete>
                        </v-col>
                    </v-row>
                    <v-row no-gutters class="my-2">
                        <v-col cols="12">
                            <v-text-field
                                v-model="releaseQty"
                                prepend-icon="mdi-database-outline"
                                hide-details
                                label="Quantity to Release"
                                type="number"
                                :max="item.quantity"
                                :rules="[
                                    rules.required,
                                    rules.number,
                                    numberNotValid,
                                ]"
                                required
                            >
                            </v-text-field>
                        </v-col>
                    </v-row>
                </v-form>
            </v-card-text>
            <v-card-actions>
                <v-spacer />
                <v-btn
                    :loading="loading"
                    color="primary"
                    class="mr-2 mb-3"
                    rounded
                    :disabled="!valid"
                    @click="release"
                >
                    RELEASE
                </v-btn>
            </v-card-actions>
        </v-card>
    </div>
</template>

<script>
import API from '@/services/api'

export default {
    name: 'ReleaseReserve',
    props: {
        item: {
            type: Object,
            default: () => {},
        },
    },
    data: () => ({
        loading: false,
        valid: false,
        releaseQty: null,
        selectedProject: undefined,
        projects: [],
        rules: {
            required: v => !!v || 'value is required',
            number: v => v > 0 || 'The value must be greater than 0',
        },
    }),
    async mounted() {
        this.loading = true
        this.projects = await API.getLiteProjects({})
        this.loading = false
    },
    methods: {
        numberNotValid(value) {
            return (
                value <=
                    (this.item.quantity
                        ? this.item.quantity
                        : parseInt(this.item.free_use || 0)) ||
                'The value is not valid'
            )
        },
        close() {
            this.$emit('closeDialog')
        },
        async release() {
            try {
                this.loading = true
                if (
                    this.releaseQty >
                    (this.item.quantity || parseInt(this.item.free_use) || 0)
                ) {
                    this.setErrorItems({
                        source: this.$options.name,
                        message:
                            'The quantity selected cannot be greater than the quantity reserved',
                    })
                    return null
                }
                const newRelease = {
                    projectId: this.selectedProject,
                    quantity: this.releaseQty,
                }
                await this.$emit('release', newRelease)
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
    },
}
</script>

<style></style>
